const CategoryList = [
  {
    key:"PROMOTION",
    title:"행사 품목",
    description:"오드비에서 진행하는 행사 제품들을 모아 두었습니다. 좋은 기회에 좋은 위스키를 즐겨보세요.",
    url: "/"
  },
  {
    key:"SCOTCH_SINGLE",
    title:"스카치 위스키",
    description:"스코틀랜드는 위스키의 발상지로 전 세계적으로 유명합니다. 스코틀랜드 위스키는 그 특유의 풍미와 품질로 인정받고 있으며, 크게 하이랜드(Highland), 스페이사이드(Speyside), 로우랜드(Lowland), 아일라(Islay), 캠벨타운(Campbeltown)의 5개 주요 지역으로 나뉩니다. 각 지역은 고유의 기후와 지형적 특성을 바탕으로 독특한 맛과 향을 지닌 위스키를 생산합니다.",
    url: "/"
  },{
    key:"SCOTCH_ISLAY_SINGLE",
    title:"스카치 아일라 위스키",
    description:"스코틀랜드의 아일라 섬에서 생산되는 위스키로, 독특한 스모키하고 피트 향이 특징입니다. 이 지역의 위스키는 종종 강렬한 바다의 풍미와 함께 강한 스모크와 미네랄 노트를 가지며, 복잡한 맛의 깊이를 제공합니다. 아일라 위스키는 주로 원주율이 높은 캐스크 스트렝스와 스모키한 특성을 지닌 제품들이 많아, 독특하고 강렬한 풍미를 경험할 수 있습니다.",
    url: "/"
  },{
    key:"IRISH",
    image:"/images/ireland.jpg",
    title:"아이리쉬 위스키",
    description:"아이리시 위스키는 아일랜드에서 생산되는 위스키로, 일반적으로 부드럽고 깔끔한 맛이 특징입니다. 대개 3중 증류 과정을 거쳐 더 매끄럽고 순한 맛을 가지며, 캐릭터는 종종 바닐라, 과일, 그리고 약간의 스파이시함이 어우러진 복합적인 풍미를 보여줍니다. 아이리시 위스키는 몰트, 그레인, 또는 둘의 혼합으로 만들어지며, 스트레이트로 마시거나 다양한 칵테일에 사용하기에 적합한 위스키입니다.",
    url: "/"
  },{
    key:"INDEPENDENT",
    title:"한정판 위스키",
    description:"특정 증류소에서 생산된 원주를 독립적으로 병입하여 판매하는 위스키를 의미합니다. 이러한 위스키는 특정 배치나 캐스크에서 직접 병입되어, 증류소의 공식 제품보다 더욱 독특하고 개성 있는 맛과 향을 제공하는 경우가 많습니다. 독립병입자는 원주를 병입하기 전에 추가적인 숙성이나 블렌딩을 하지 않고, 원주가 가진 고유의 특성과 개성을 그대로 유지합니다. 이로 인해, 독특한 캐스크의 특성과 깊이 있는 맛을 경험할 수 있어 위스키 애호가들에게 큰 인기를 끌고 있습니다.",
    url: "/"
  },{
    key:"PREMIUM",
    title:"프리미엄 위스키",
    description:"프리미엄 위스키에는 각 위스키 브랜드 별로 최상위 등급을 모아두었습니다.",
    url: "/"
  },{
    key:"BOURBON",
    image:"/images/america.jpg",
    title:"아메리칸 위스키(버번,라이)",
    description:"아메리칸 위스키는 미국에서 생산되는 다양한 스타일의 위스키를 포함하며, 주로 버번, 라이, 테네시 위스키 등이 있습니다. 버번 위스키는 주로 옥수수로 만들어지고, 오크통에서 숙성되어 달콤하고 바닐라, 카라멜의 풍미가 특징입니다. 라이 위스키는 호밀을 주원료로 하여, 더 매콤하고 스파이시한 맛을 지니고 있습니다. 테네시 위스키는 버번과 유사하지만, 특유의 필터링 과정인 [링컨 카운티 프로세스]를 통해 부드럽고 깨끗한 맛을 제공합니다.",
    url: "/"
  },{
    key:"COGNAC",
    title:"브랜디(꼬냑, 깔바도스, 알마냑 등)",
    description:"과일, 주로 포도를 발효시켜 만든 주류로, 증류 과정을 통해 알코올 농도를 높이고 깊은 풍미를 더합니다. 이 음료는 다양한 과일에서 제조될 수 있지만, 포도를 사용하는 것이 가장 일반적입니다. 브랜디는 오크통에서 숙성되면서 부드럽고 복합적인 맛을 얻으며, 향긋한 과일 노트와 함께 바닐라, 캐러멜, 스파이스 같은 풍미가 조화를 이룹니다.",
    url: "/"
  },{
    key:"ETC",
    image:"/images/whiskey2.jpg",
    title:"아시안 위스키",
    description:"대만과 인도의 위스키는 각 나라의 독특한 제조 방식과 풍미를 자랑합니다. 대만의 위스키는 주로 카발란(Kavalan) 브랜드로 유명하며, 열대 기후에서 빠른 숙성과 깊이 있는 풍미가 특징입니다. 인도 위스키는 독특한 열대 원료와 숙성 환경으로 인해 강렬하고 스파이시한 맛이 특징이며, 오크와 스파이스, 카라멜 노트가 어우러지는 경우가 많습니다. 두 나라 모두 각자의 전통과 환경에 맞춘 위스키를 생산하여, 다양한 맛과 향을 제공합니다.",
    url: "/"
  },{
    key:"LIQUEUR",
    title:"리큐르",
    description:"증류주에 설탕, 과일 주스, 허브, 향신료 등을 추가하여 맛과 달콤함을 더한 주류입니다. 일반적으로 알코올 농도는 15%에서 30% 사이이며, 다양한 맛과 향을 제공합니다. 리큐어는 전통적으로 디저트, 칵테일, 또는 단독으로 즐기며, 풍부한 풍미와 달콤함 덕분에 다양한 음료에 활용됩니다. 예를 들어, 그랑 마르니에(Grand Marnier)와 같은 오렌지 리큐어나, 카카오와 커피 맛을 지닌 리큐어가 그 예입니다.",
    url: "/"
  },{
    key:"VODKA",
    title:"보드카",
    description:"곡물이나 서류 등 녹말이나 당을 함유한 재료를 당화, 발효시켜 양조주를 만들고, 이를 원재료의 향미가 없어질 때 까지 여러 번 증류/여과하여 제조하는 증류주(스피리츠). 무색·무미·무취를 특징으로 추구하며, 유럽과 미국 & 캐나다 그리고 폴란드와 러시아 등 세계적으로 사랑받고 있는 술입니다.",
    url: "/"
  },{
    key:"RUM",
    title:"럼",
    description:"사탕수수즙 또는 당밀 등의 제당 공정 부산물을 발효·증류시켜 만든 증류주이며, 달콤한 냄새와 특유의 맛이 있습니다",
    url: "/"
  },{
    key:"GIN",
    title:"진",
    description:"증류주의 한 종류이며, 알코올 도수는 대개 40도 이상이며 주로 칵테일을 제조하는 데 많이 사용됩니다.",
    url: "/"
  },{
    key:"TEQUILA",
    title:"데킬라",
    description:"멕시코에서 생산되는 술이며, 증류주 중 하나로 알콜 농도는 38~52%. 할리스코 주에 위치한 테킬라(Tequila)라는 지역의 이름을 딴 것으로 유명합니다.",
    url: "/"
  },{
    key:"OTHER",
    title:"오드비 신규",
    description:"새로 들어온 다양한 종류의 스피릿을 만나 보실 수 있습니다.",
    url: "/"
  },{
    key:"COCKTAIL",
    title:"칵테일",
    url: "/"
  }
];

export default CategoryList;
